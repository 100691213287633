import clsx from 'clsx'
import React from 'react'

import { LoadingIndicator } from '~ui/LoadingIndicator'

export const Loading: React.FC<{ className?: string }> = ({ className }) => (
  <div
    className={clsx(
      'h-full w-full flex items-center justify-center text-primary-500',
      className,
    )}
  >
    <LoadingIndicator className="w-8 h-8" />
  </div>
)
